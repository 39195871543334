import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Layout from '../components/shared/layout'
import SEO from '../components/shared/seo'

const ArticlesPage = ({ data }) => {
  
  return (
    <Layout>

      <SEO title='Articles' />

      <Container>
        <PageTitle>Articles</PageTitle>
        <PageSubtitle>The latest news about the developements of MAKE101</PageSubtitle>
        <hr />
        <ArticleWrapper>
          <ArticleContainer>
            {data.allMarkdownRemark.edges.map(({ node }, ...index) => (
              <ArticleItem key={index}>
                <Link to={node.frontmatter.path}>
                  <time>{node.frontmatter.date}</time>
                  <ArticleTitle>{node.frontmatter.title}</ArticleTitle>
                </Link>
              </ArticleItem>
            ))}
          </ArticleContainer>
        </ArticleWrapper>
      </Container>
    </Layout>
  )
}

export default ArticlesPage

const Container = styled.div`${tw`max-w-lg m-auto`}`
const PageTitle = styled.h1`${tw`font-bold mt-16`}`
const PageSubtitle = styled.h2`${tw`font-normal text-xl`}`
const ArticleWrapper = styled.div`${tw`py-8`}`
const ArticleContainer = styled.ul`${tw`list-none p-0`}`
const ArticleItem = styled.li`${tw`p-0`}`
const ArticleTitle = styled.h3`${tw`mt-2`}`
// const ItemTitle = styled.h3`${tw`text-black m-4`}`
// const ItemExcerpt = styled.p`${tw`text-black m-4`}`
// const Date = styled.div`${tw`text-sm text-gray-600 m-4`}`

export const pageQuery = graphql`

  query {
    allMarkdownRemark(
      filter: {
        frontmatter: { 
          type: { eq: "article" },
          draft: { ne: true }
        }
      }
      sort: {order: DESC, fields: frontmatter___date},
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
            path
          }
          html
        }
      }
    }
  }`
